import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Home from './pages/Home';
import Blog from './pages/Blog';
import ArticlePage from './pages/ArticlePage';
import BreakingNews from './components/sections/BreakingNews';
import Contact from './pages/contact';
import BreakingNewsBanner from './components/BreakingNewsBanner';
import GeniusFramework from './pages/GeniusFramework';
import EdgeIntelligence from './pages/EdgeIntelligence';

// Import des styles
import './styles/color-override.css';
import './styles/fix-styles.css';
import './styles/apple-design.css'; 
import './styles/force-menu-fixes.css'; // Force l'application des styles pour les menus
import './styles/temp-icons.css'; // Icônes temporaires pour la section Impact

function App() {
  return (
    <Router>
      <div className="min-h-screen">
        <BreakingNewsBanner />
        <BreakingNews />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<ArticlePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/GeniusFramework" element={<GeniusFramework />} />
          <Route path="/EdgeIntelligence" element={<EdgeIntelligence />} />
          
          {/* Redirections temporaires pour la transition de news à blog */}
          <Route path="/news" element={<Navigate to="/blog" replace />} />
          <Route path="/news/:id" element={<Navigate to="/blog/:id" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
