import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

type Solution = {
  id: string;
  title: string;
  description: string;
  icon: string;
  color: string;
  delay: number;
};

const solutions: Solution[] = [
  {
    id: 'edge-intelligence',
    title: 'RAID 0 Neuronal By Mr D.',
    description: 'La révolution de l\'intelligence artificielle distribuée pour une performance maximale sur Apple Silicon.',
    icon: '/images/edge-ai-icon.png',
    color: 'bg-gradient-blue',
    delay: 0,
  },
  {
    id: 'genius-framework',
    title: 'Genius Framework',
    description: 'Apprentissage adaptatif qui évolue avec vos besoins pour des solutions intelligentes.',
    icon: '/images/genius-ai-icon.png',
    color: 'bg-gradient-purple',
    delay: 0.1,
  },
  {
    id: 'music-ai',
    title: 'Music AI',
    description: 'Innovation musicale révolutionnaire propulsée par notre technologie adaptative exclusive.',
    icon: '/images/music-ai-icon.png',
    color: 'bg-gradient-orange',
    delay: 0.2,
  },
];

const SolutionsGrid = () => {
  const navigate = useNavigate();
  
  return (
    <section className="pb-10 pt-5" id="solutions">
      <div className="container mx-auto px-4">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-8"
        >
          <h1 className="text-5xl md:text-6xl font-bold mb-5 text-[#333333]">
            Empowering Innovation
          </h1>
          <p className="text-xl md:text-2xl mb-8 mx-auto max-w-4xl text-[#666666]">
            At Infinity Cloud, we are revolutionizing AI for education,
            open-source collaboration, and inclusive infrastructure.
          </p>
          
          <span className="text-xl font-medium inline-block mb-3"
                style={{
                  background: 'linear-gradient(90deg, #0066cc, #5ac8fa)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent'
                }}>
            Nos Solutions
          </span>
          <h2 className="text-4xl md:text-5xl font-semibold mb-6 text-[#333333]">
            Technologie innovante
          </h2>
          <p className="max-w-2xl mx-auto text-lg text-[#666666]">
            Des solutions d'IA éthiques, performantes et adaptées à vos besoins spécifiques.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8 mt-10">
          {solutions.map((solution) => (
            <motion.div
              key={solution.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: solution.delay }}
              whileHover={{ y: -15, transition: { duration: 0.3 } }}
              className="solution-card"
              style={{
                animation: `float 6s ease-in-out infinite ${solution.delay * 2}s`,
                boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
                borderRadius: '24px',
                overflow: 'hidden',
                backgroundColor: 'white',
                transform: 'translateZ(0)',
                position: 'relative'
              }}
            >
              <div 
                style={{
                  background: solution.id === 'edge-intelligence' 
                    ? 'linear-gradient(135deg, #0066cc, #5ac8fa)' 
                    : solution.id === 'genius-framework'
                      ? 'linear-gradient(135deg, #5e5ce6, #bf5af2)'
                      : 'linear-gradient(135deg, #ff9500, #ff2d55)',
                  padding: '20px',
                  borderTopLeftRadius: '24px',
                  borderTopRightRadius: '24px'
                }}
              >
                <img 
                  src={solution.icon} 
                  alt={solution.title} 
                  className="w-20 h-20 mx-auto my-3 drop-shadow-lg"
                  style={{ display: 'block' }}
                />
              </div>
              <div style={{ padding: '24px' }}>
                <h3 style={{ fontSize: '22px', fontWeight: 600, marginBottom: '10px', color: '#333333' }}>{solution.title}</h3>
                <p style={{ fontSize: '16px', color: '#666666', lineHeight: 1.5 }}>{solution.description}</p>
                <div className="mt-5">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '36px',
                      height: '36px',
                      borderRadius: '50%',
                      backgroundColor: solution.id === 'edge-intelligence' 
                        ? '#0066cc' 
                        : solution.id === 'genius-framework'
                          ? '#5e5ce6'
                          : '#ff9500',
                      color: 'white',
                      border: 'none',
                      padding: 0,
                      cursor: 'pointer',
                      boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
                    }}
                    onClick={() => {
                      if (solution.id === 'genius-framework') {
                        navigate('/GeniusFramework');
                      } else if (solution.id === 'edge-intelligence') {
                        navigate('/EdgeIntelligence');
                      }
                    }}
                  >
                    <svg 
                      className="w-5 h-5" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M9 5l7 7-7 7" 
                      />
                    </svg>
                  </motion.button>
                </div>
              </div>

              {/* Effet de lumière */}
              <div 
                style={{
                  position: 'absolute',
                  width: '300px',
                  height: '300px',
                  borderRadius: '50%',
                  background: solution.id === 'edge-intelligence' 
                    ? 'radial-gradient(circle, rgba(92, 170, 255, 0.15) 0%, rgba(255, 255, 255, 0) 70%)' 
                    : solution.id === 'genius-framework'
                      ? 'radial-gradient(circle, rgba(191, 90, 242, 0.15) 0%, rgba(255, 255, 255, 0) 70%)'
                      : 'radial-gradient(circle, rgba(255, 149, 0, 0.15) 0%, rgba(255, 255, 255, 0) 70%)',
                  zIndex: -1,
                  opacity: 0.6,
                  top: '-40px',
                  right: '-40px',
                  filter: 'blur(40px)'
                }}
              ></div>
            </motion.div>
          ))}
        </div>
      </div>

      <style>
        {`
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
        `}
      </style>
    </section>
  );
};

export default SolutionsGrid; 